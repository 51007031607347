import React from 'react';
import { toast } from 'react-toastify';
import { components } from 'react-select';
import ExcelJS from 'exceljs';
import Excel from 'exceljs';

// Components
import Checkbox from '../Checkbox';
import PyeCalendarSelector from '../Settings/PlanEditor/PlanCalendarSelect';
import Listable from './Listable';
import ColoredTag from '../ColoredTag';
import Filter from './Filter';
import Spreadsheet from './spreadsheet/table';
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';
import Highlight from '../Highlight';
import helpers from './helpers'

// Styles
import '../../styles/investments-plan-summary.css';
import '../../styles/investments.css';
import '../../styles/layout.css';
import '../../styles/planeditor.css';

import api from '../../api';
import DoughnutChart from './DoughnutChart';

export default class PlanSummary extends React.Component {

    abortController = new AbortController();

    state = {
        updatingPlan: false,
        aggregate: "Aggregate by month",
        plan: null,
        accounts: [],
        editingAccount: null,
        investmentProviders: [],
        filteredAccounts: [],
        cells: [],
        columnDefs: [],
        rowDefs: [],
        headers: [],
        overflowId: null,
        isSaving: false,
        editingPlan: null,
        accountCells: [],
        subTypes: {},
        accountMetaMapping: {},
    }

    TAGCOLORS = {
        'brokerage': {
            backgroundColor: 'var(--color-primary)',
        },
        'rk': {
            backgroundColor: 'var(--color-accent)',
        },
        'missing': {
            backgroundColor: 'var(--palette-color-1)',
        },
        'todo': {
            backgroundColor: 'var(--color-accent)',
        },
        'onTrack': {
            backgroundColor: 'var(--color-primary)',
        },
        'complete': {
            backgroundColor: 'var(--palette-color-3)',
        },
        'na': {
            backgroundColor: 'var(--palette-color-6)',
        },
    }

    accountTypes = [
        { value: 'BROKERAGE', label: 'BROKERAGE', color: '#00C1EF' },
        { value: 'RK', label: 'RK', color: '#FF5D00' }
    ];
    
    componentDidMount() {
        this.props.setTitle("Trust Accounting · Plan Summary");
        this.init(); 
    }

    componentDidUpdate = (prev) => {
        // If the plan year changed, reload the statements...  
        if(prev.planYear !== this.props.planYear) 
            this.init(); 
        
        if (JSON.stringify(prev.filters) !== JSON.stringify(this.props.filters) || prev.query !== this.props.query) {
            this.filterAccounts();
        }
    }

    init = async () => {
        this.setState({loading: true});
        await this.fetchInvestmentProviders();
        await this.loadAccounts();
        await this.loadPlan();
        await this.loadStatements();  
        await this.fetchTransactions();
        this.filterAccounts();
        this.formatToTable(); 
        this.formatAccountsToCells();
        this.setState({loading: false}); 
    }

    loadPlan = async () => {
        const res = await api.get(`/tpa/plan/get?planId=${this.props.match.params.planId}`);
        if(!res.success)
            return; //TODO: Handle error with toast message....
        this.setState({plan: {...res.data}})
        this.props.setPlan(res.data)
    }

    loadAccounts = async() => {
        const res = await api.post(`/tpa/account/list?planId=${this.props.match.params.planId}`, {
            includeAssets: true, 
            planYear: this.props.planYear,
            planId: this.props.match.params.planId,
            resultLimit: 10000,
        });
        if(!res.success)
            return; //TODO: Handle error with toast message.... 
        this.setState({ accounts: res.accounts }); 
        this.props.setAccounts(res.accounts);
    }   

    loadStatements = async() => {
        // If we dont have a plan... dont make the request. 
        if(!this.state.plan)
            return;

        const res = await api.post('/tpa/statement/list', {
            planYear: this.props.planYear, 
            planId: this.state.plan.internalPlanId, 
            resultLimit: 10000
        })
        if(!res.success)
            return; //TODO: Handle error with toast message....
        this.setState({statements: res.statements});
    }

    filterAccounts = () => {

        let filteredAccounts = [...this.state.accounts];

        // Do we have a search query? 
        if(this.props.query) {
            const query = this.props.query.toLowerCase();
            filteredAccounts = filteredAccounts.filter((account) => {
                // Does any key value contain the query? 
                return Object.entries(account).some(([key, value]) => {
                    if (['_id', 'team', 'planId'].includes(key)) return false;

                    if(typeof value === 'string') {
                        return value.toLowerCase().includes(query);
                    }
                    else if(Array.isArray(value)) {
                        return value.some((v) => v.toString().toLowerCase().includes(query));
                    }
                    else if (key === 'status') {
                        return Object.entries(value).some(([year, _status]) => {
                            return String(year) === String(this.props.planYear) && _status.toLowerCase().includes(query);
                        });
                    }
                    return false;
                });
            });
        }

        const filters = {...this.props.filters}; 

        if(Object.values(filters).some((f) => f !== null && f.length > 0)) {

            filteredAccounts = filteredAccounts.filter((a) => {

                return Object.entries(filters).every(([key, value]) => {
                    if(value === null || value.length === 0) {
                        return true; 
                    }
                    if(key === 'provider') {
                        return value.includes(a.provider);
                    }
                    else if(key === 'accountType') {
                        return value.includes(a.accountType?.toLowerCase());
                    }
                    else if(key === 'account') {
                        return value.includes(a.number?.toLowerCase());
                    }
                    else if(key === 'status') {
                        return value.includes(a.status?.[this.props.planYear]?.toLowerCase()) || (value.includes("missing") && a.status?.[this.props.planYear] === undefined);
                    }
                    else if(key === 'payee') {
                        return value.map(v => v.toLowerCase()).includes(a.payee?.toLowerCase());
                    }
                    return true; 
                });
            });
        }

        this.setState({filteredAccounts}); 
    }

    savePlan = async (plan) => {
        // We need to make sure that that the plan has a valid ID and name
        if (!plan?.internalPlanId)
            return toast.error("Plan must have a valid ID.");

        if (!plan?.planName)
            return toast.error("Plan must have a name.");

        for(const key of Object.keys(plan)){
            if(typeof plan[key] === "string")
                plan[key] = plan[key].trim();
        }

        this.setState({plan, updatingPlan: false, editingPlan: null});

        const res = await api.post('/tpa/plan/update', {
            id: plan._id,
            planDiff: plan
        });

        // If the update was successful, we're done :)
        if (res.success) {
            toast.success("Plan saved successfully.");
            return;
        }

        toast.error("Failed to save the plan.");
    }

    renderPlanInformation = () => {
        const plan = {...this.state.plan}
        return (
            <div
                className='plan-summary-plan-information'
            >
                <div
                    className='plan-summary-plan-information-details-container'
                >
                    <div style={{display: 'flex'}}>
                        <h2 style={{ fontWeight: 600 }}>Plan Information</h2>
                        { this.state.updatingPlan &&
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <div
                                    className='a'
                                    style={{
                                        paddingLeft: 20,
                                        backgroundImage: 'url("https://static.stax.ai/images/icons/confirm.png")',
                                        backgroundSize: 15,
                                        backgroundRepeat: 'no-repeat',
                                        marginLeft: 30,
                                        fontSize: 17,
                                        backgroundPosition: 'left center',
                                        display: 'flex',
                                    }}
                                    onClick={async () => await this.savePlan(this.state.editingPlan)}
                                >
                                    Save
                                </div>
                                <div
                                    className='a'
                                    style={{
                                        backgroundImage: 'url("https://static.stax.ai/images/icons/delete.png")',
                                        color: 'var(--color-accent-dark)',
                                        marginLeft: 10,
                                        fontSize: 17,
                                        backgroundSize: 15,
                                        backgroundRepeat: 'no-repeat',
                                        backgroundPosition: 'left 5px',
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                        width: 70
                                    }}
                                    onClick={() => {this.setState({ updatingPlan: null, editingPlan: null})}}
                                >
                                    Cancel
                                </div>
                            </div>
                            
                        }
                    </div>
                    <div style={{clear: 'both', height: 30}}/>
                    <div className="plan-editor-information">
                        <div className="plan-editor-information-id">
                            <h3>Plan ID</h3>
                            <input
                                type="text"
                                value={this.state.editingPlan?.internalPlanId ?? plan?.internalPlanId}
                                onChange={e => {
                                    this.setState({
                                        editingPlan: {
                                            ...(this.state.editingPlan ?? plan),
                                            internalPlanId: e.target.value
                                        },
                                        updatingPlan: true
                                    });
                                }}
                                placeholder="TPA Plan ID..."
                            />
                        </div>
                        <div className="plan-editor-information-name">
                            <h3>Plan Name</h3>
                            <input
                                type={"text"}
                                value={this.state.editingPlan?.planName ?? plan?.planName}
                                onChange={e => {
                                    this.setState({
                                        editingPlan: {
                                            ...(this.state.editingPlan ?? plan),
                                            planName: e.target.value
                                        },
                                        updatingPlan: true
                                    });
                                }}
                                placeholder="Plan name..."
                            />
                        </div>
                        <div className="plan-editor-information-type">
                            <h3>Plan Type</h3>
                            <input
                                type={"text"}
                                // disabled={this.props.ppConnections?.length !== 0}
                                value={this.state.editingPlan?.planType ?? plan?.planType ?? ''}
                                onChange={e => {
                                    this.setState({
                                        editingPlan: {
                                            ...(this.state.editingPlan ?? plan),
                                            planType: e.target.value
                                        },
                                        updatingPlan: true
                                    });
                                }}
                                placeholder="Plan Type..."
                            />
                        </div>
                        <div className="plan-editor-information-pye">
                            <h3>Plan Year End</h3>
                            <PyeCalendarSelector
                                endMm={this.state.editingPlan?.endMm ?? plan?.endMm ?? 12}
                                endDd={this.state.editingPlan?.endDd ?? plan?.endDd ?? 31}
                                updateMm={(type, value) => {
                                    this.setState({
                                        editingPlan: {
                                            ...(this.state.editingPlan ?? plan),
                                            endMm: value
                                        },
                                        updatingPlan: true
                                    });
                                }}
                                updateDd={(type, value) => {
                                    this.setState({
                                        editingPlan: {
                                            ...(this.state.editingPlan ?? plan),
                                            endDd: value
                                        },
                                        updatingPlan: true
                                    });
                                }}
                                onChange={(e) => {}}
                                style={{right: 'auto'}}
                            />
                        </div>
                        <div className="plan-editor-information-terminated">
                            <Checkbox checked={this.state.editingPlan ? !this.state.editingPlan?.active : plan?.active === false ? true : false} onToggle={() => {
                                this.setState(prevState => ({
                                    editingPlan: {
                                        ...(this.state.editingPlan ?? plan),
                                        active: prevState.editingPlan?.active !== undefined ?  !prevState.editingPlan.active : !plan?.active
                                    },
                                    updatingPlan: true
                                }));

                                // Have a toast if the user is trying to terminate a plan to Pension Pro
                                if (plan?.active && this.props.ppConnections?.length !== 0)
                                    toast.info("Note: This plan will not be terminated in Pension Pro, please deactivate the plan manually in Pension Pro")

                            }} />
                            <h3>Terminated</h3>
                        </div>
                        <div className="plan-editor-information-directory">
                            <h3>Plan Directory</h3>
                            <input
                                type={"text"}
                                value={this.state.editingPlan?.directory ?? plan?.directory ?? ''}   
                                onChange={e => {
                                    this.setState({
                                        editingPlan: {
                                            ...(this.state.editingPlan ?? plan),
                                            directory: e.target.value
                                        },
                                        updatingPlan: true
                                    });
                                }}
                                placeholder="Path to plan directory..."
                            />
                        </div>
                        <div
                            className='plan-editor-information-assignee'
                        > 
                            <h3>Plan Assignees</h3>
                            <Select 
                                isMulti
                                className={"multi-select-container"}
                                classNamePrefix={"multi-select"}
                                options={this.props.team?.users?.map((u) => {
                                    return {
                                        value: u._id,
                                        label: u.name
                                    }
                                })}
                                value={
                                    this.state.editingPlan?._id ?
                                    this.state.editingPlan?.assignees?.map((a) => {
                                    return {
                                        value: a,
                                        label: this.props.team?.users?.find((u) => u._id === a)?.name
                                    }})
                                    :
                                    plan?.assignees?.map((a) => {
                                        return {
                                            value: a,
                                            label: this.props.team?.users?.find((u) => u._id === a)?.name
                                        }
                                    })
                                }
                                onChange={(e) => {
                                    this.setState({
                                        editingPlan: {
                                            ...(this.state.editingPlan ?? plan),
                                            assignees: e? e.map((a) => a.value) : []
                                        },
                                        updatingPlan: true
                                    });
                                }}
                                styles={{
                                    valueContainer: (base) => ({
                                        ...base,
                                        flexWrap: 'nowrap',
                                    })
                                }}
                            />
                        </div>
                    </div>

                </div>
                <div
                    className='plan-summary-plan-information-accounting-status-container'
                >
                    {/* <h2 style={{ fontWeight: 600 }}>Accounting Status</h2> */}
                    <DoughnutChart 
                        title="Accounting Status"
                        labels={['Missing', 'Todo', 'On Track', 'Complete', 'N/A']} 
                        styles={{width: 300, height: 300}}
                        colors={[
                            '#ff4352',
                            'rgb(255, 129, 59)',
                            'rgb(0, 193, 239)',
                            '#00b770',
                            '#506f79'
                        ]}
                        data={(() => {
                            // If we dont have statements, return undefined
                            if(!this.state.statements)
                                return undefined;

                            // Count the number of statements in each category...  
                            let counts = {
                                'missing': 0,
                                'todo': 0,
                                'onTrack': 0,
                                'complete': 0,
                                'na': 0
                            };
                            this.state.accounts.forEach((account) => {
                                counts[(account.status?.[this.props.planYear] || 'missing')] += 1;
                            });

                            return [counts.missing, counts.todo, counts.onTrack, counts.complete, counts.na];
                        })()}
                        
                    />  
                </div>
            </div>
        )
    }

    calculatedGainLoss = (endingBalance, beginningBalance, transactions) => {
        const {
            deposits, 
            withdrawals,
            income, 
            fees, 
            transfers, 
            other,
            reinvestments,
            loanPayments,
            rollovers,
            recievables, 
        } = transactions;

        return (endingBalance??0) - (
            (beginningBalance??0) +
            (deposits??0) +
            (withdrawals??0) +
            (income??0) +
            (fees??0) +
            (transfers??0) +
            (other??0) +
            (reinvestments??0) +
            (loanPayments??0) +
            (rollovers??0) +
            (recievables??0)
        );
    }

    formatToTable = () => {
        const labelMapping = helpers.labelMapping; 
        const transactionTypeMapping = helpers.transactionTypeMapping;


        // All possible months for trust accounting, assuming individual statements for each. 
        let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']; 
        // Sort the months based on plan year end... 
        let startMmIndex = (this.state.plan.endMm??12) % 12;
        months = [...months.slice(startMmIndex), ...months.slice(0, startMmIndex)];

        // We need to simplify the statements. We will start by simplifing for each account seperately. then bringing together at the end. 
    
        // Create a mapping from accountNumber to accountType
        const accountTypeMap = {};
        this.state.accounts.forEach(account => {
            accountTypeMap[account.number] = account.accountType;
        });
    

        // Group the statements by account number...
        let accounts = Object.groupBy(this.state.statements, (s) => s.accountNumber);
    
        // Filter out accounts whose accountType is "ignore"
        accounts = Object.fromEntries(
            Object.entries(accounts).filter(([accountNumber, statements]) => {
                return accountTypeMap[accountNumber] !== "ignore";
            })
        );
    
        // For each account, simplify the statements
        const accountsEntries = Object.entries(accounts);
        for (const [accountNumber, statementsArray] of accountsEntries) {
            const reducedStatements = helpers.reduceStatements(
                statementsArray, 
                this.state.plan, 
                this.state.transactions, 
                this.props?.team?.ta?.txDetail
            );
            accounts[accountNumber] = reducedStatements;
        }
    
        // Now that we have cleaned/reduced the statements. Flatten into a single array, and combine everything. 
        let statements = Object.values(accounts).flat();  

        // Sort the statements by shortest -> Longest coverage...  
        statements.sort((a,b) => {
            if(a.months.length !== b.months.length)
                return b.months.length - a.months.length;
            else 
                return months.indexOf(a.months[0]) - months.indexOf(b.months[0]); 
        });

        statements = statements.reduce((acc, statement) => {
            const parent = acc.find(s => statement.months.every(m => s.months.includes(m)));
            if(!parent)
                return [
                    ...acc, 
                    statement
                ]
            
            const firstMonth = statement.months[0];
            const lastMonth = statement.months[statement.months.length - 1]; 

            // remove the parent from the acc...
            acc = acc.filter(s => s !== parent);
            const combined =  helpers.combineStatements(parent, statement); 
            
            if(firstMonth !== parent.months[0]){
                combined.beginningBalance = parent.beginningBalance;
            }

            if(lastMonth !== parent.months[parent.months.length - 1]){
                combined.endingBalance = parent.endingBalance;
                combined.balanceAccrued = parent.balanceAccrued;
            }

            return [
                ...acc, 
                combined
            ]
        }, []);
        
        const expectedCoverage = helpers.expectedCoverage(statements, this.state.plan, this.props.planYear);

        // Now we need to build the headers, (as keys) and include any months that are not covered by statements...
        let headers = [
            ...statements.map(s => s.columnKey), 
            ...months.filter(m => !statements.some(s => s.months.includes(m)))
        ].sort((a, b) => months.indexOf(a.split(" - ")[0]) - months.indexOf(b.split(" - ")[0]));

        // Sort the statements... 
        statements = statements.sort((a, b) => headers.indexOf(a.columnKey) - headers.indexOf(b.columnKey));

        const columnDefs = [
            {
                key: 'rowLabel',
                style: {
                    fontWeight: 600,
                    position: 'sticky',
                    left: 0,
                    backgroundColor: 'var(--color-background)',
                }
            },
            ...headers.map(h => ({
                key: h, 
                style: {
                    alignItems: 'end'
                },
            })),
            {
                key: 'total', 
                style: {
                    position: 'sticky',
                    right: 0,
                    backgroundColor: 'var(--color-background)',
                    alignItems: 'end'
                }
            }
        ]

        const rowDefs = [
            {
                key: 'beginningBalance', 
                style:{
                    borderTop: '1px solid var(--color-border-light)', 
                    borderBottom: '1px solid var(--color-border-light)',
                    height: 50,
                }
            },
            {key: 'deposits'},
            {key: 'withdrawals'},
            {key: 'income'},
            {
                key: 'fees',
                style:{
                    borderBottom: '1px solid var(--color-border-light)',
                }
            },
            {key: 'loanPayments'},
            {key: 'transfers'},
            {key: 'rollovers'},
            {key: 'reinvestments'},
            {key: 'other'},
            {
                key: 'gain_loss',
                style:{
                    borderTop: '1px solid var(--color-border-light)', 
                    borderBottom: '1px solid var(--color-border-light)'
                },
            },
            {key: 'endingBalance'},
            {key: 'accrued'},
            {
                key: 'balanceAccrued',
                style:{
                    borderTop: '1px solid var(--color-border)', 
                    borderBottom: '6px double var(--color-border)'
                },
            },
        ]

        headers = columnDefs.map(def => {

            const expecting = expectedCoverage.includes(def.key.split(" - ")[0]); 

            return {
                columnKey: def.key,
                value: def.key === 'rowLabel'? '': def.key === 'total'? "Total": def.key.toUpperCase(),
                data: def.key === 'rowLabel' || def.key === 'total'? null: {
                    missing: expecting, 
                    na: !expecting,
                    planYear: this.props.planYear
                }, 
                showActions: false, 
            }
        });
        
        const subTypes = {};

        // Now we need to build the cells...  
        const cells = statements.reduce((acc, statement, statementIdx) => {
            // What is the column key for this statement?  
            const columnKey = statement.columnKey;

            const header = headers.find(h => h.columnKey === columnKey); 
            header.data.errorCount = 0; 
            header.data.missing = false; 
            header.data.reconciles = statement.reconciles;
            header.data.confirmed = statement.confirmed;
            
            // Get the previous and next statement...   
            const statementTransactions = this.state.transactions?.filter(t => {
                return (t.statement === statement._id || (statement.statements??[])?.includes(t.statement))
            });
            
            // For each statementTransactions, check for subtypes. 
            if (statementTransactions) 
                statementTransactions.forEach(t => {
                    if(!t.txSubType) return; 

                    const type = t.txType; 
                    const subType = t.txSubType;
                    const amount = t.amount;  
                    const rowDef = Object.entries(transactionTypeMapping).find(([key, value]) => value === type)?.[0]; 

                    // If the subType does not exist, create it. 
                    if(!subTypes[rowDef])
                        subTypes[rowDef] = {}; 

                    if(!subTypes[rowDef][subType])
                        subTypes[rowDef][subType] = {};

                    if(!subTypes[rowDef][subType][columnKey])
                        subTypes[rowDef][subType][columnKey] = 0;

                    subTypes[rowDef][subType][columnKey] += amount;
                });

            const statementCells = rowDefs.map((rowDef, index) => {
                const errors = statement.errors?.filter(e => e.key === rowDef.key).map(e => {
                    return {
                        title: `Acct #${e.accountNumber}`,
                        description: "",
                        actions: [
                            {
                                label: <div className='a cell-action-review'>Review</div>,
                                action: () => {
                                    const a = this.state.accounts.find(a => a.number === e.accountNumber); 
                                    window.open('/investments/plan/' + this.props.match.params.planId + '/account/' + a._id + "?planYear=" + this.props.planYear, '_blank')
                                }
                            }
                        ],
                        level: e.level, 
                    }
                });

                header.data.errorCount += errors.length;
                header.data.errors = [...(header.data.errors??[]), ...errors];

                return {
                    columnKey: statement.columnKey, 
                    rowKey: rowDef.key, 
                    value: (statement[rowDef.key] ?? 0),
                    data: {
                        lowConfidence: false, 
                        reconciles: statement.reconciles,
                        canEdit: false,   
                    },
                    errors: errors
                }
            });

            return [...acc, ...statementCells];
        
        },  rowDefs.map(def => ({columnKey: 'rowLabel', rowKey: def.key, value: labelMapping[def.key], style:{color: 'var(--color-text)', fontWeight: 600}, data: {}})));

        // We have all statement data, and all row labels, we need to build the totals column. 
        rowDefs.forEach((rowDef) => {
            // Some keys we can ignore
            const ignore = ['rowLabel'];
            if(ignore.includes(rowDef.key))
                return;  

            let rowTotal = 0; 
            const rowCells = cells.filter(cell => cell.rowKey === rowDef.key && !ignore.includes(cell.columnKey));  
            if(rowDef.key === 'beginningBalance')
                rowTotal = rowCells[0]?.value;
            else if (rowDef.key === 'endingBalance')
                rowTotal = rowCells[rowCells.length - 1]?.value;
            else if (rowDef.key === 'balanceAccrued')
                rowTotal = rowCells[rowCells.length - 1]?.value;
            else 
                rowTotal = rowCells.reduce((acc, cell) => acc += cell.value, 0); 
            
            cells.push({
                columnKey: 'total',
                rowKey: rowDef.key, 
                value: (rowTotal??0),
                data: {} 
            });
        });

         // We need to go through all the subtypes, and create the rowDefs, and cells.  
        Object.entries(subTypes).forEach(([txType, txSubTypes]) => {
            // Find the rowDef index, that has the same key as the type.
            const rowDefIndex = rowDefs.findIndex(def => def.key === txType); 
            if(rowDefIndex === -1)
                return; 


            // Set the rowDef style to be grayed out. 
            rowDefs[rowDefIndex].style = {color: 'var(--color-text-light)'};
            
            const subTypeTotals = {}; // Dict with the header, and total for all subtypes.

            Object.entries(txSubTypes).sort((a, b) => b[0].localeCompare(a[0])).forEach(([subType, months]) => {
                // We need to add the row in for the subType. 
                rowDefs.splice(rowDefIndex + 1, 0, {key: subType});

                // Find all account's that use this subType. 
                const accounts = this.state.transactions.reduce((acc, t) => {
                    if(t.txSubType !== subType)
                        return acc; 

                    return [...new Set([...acc, t.accountNumber])]; 
                }, []);
            
                // Add in the label cell. 
                cells.push({
                    columnKey: 'rowLabel',
                    rowKey: subType,
                    value: subType,
                    style: {paddingLeft: 20},
                    data: {},
                    info: accounts.map(a => ({
                        title: `Acct #${a}`,
                        description: '',
                        actions: [
                            {
                                label: <div className='a cell-action-review'>Review</div>,
                                action: () => {
                                    const account = this.state.accounts.find(acc => acc.number === a);
                                    window.open('/investments/plan/' + this.props.match.params.planId + '/account/' + account._id + "?planYear=" + this.props.planYear, '_blank')
                                }
                            }
                        ]
                    }))
                })

                let subTypeTotal = 0; 
                headers.forEach(header => {
                    if(!header.data)
                        return; 
                    
                    const column = header.columnKey;
                    const value = months[column]??0;
                    cells.push({
                        columnKey: column,
                        rowKey: subType,
                        value: value,
                        data: {},
                    });
                    subTypeTotal += value; 

                    // Next we need to add this so we can calculate the difference between all subtypes and the total. 
                    if(!subTypeTotals[column])
                        subTypeTotals[column] = 0; 
                    subTypeTotals[column] += value;
                });

                // Add in the total cell for this subType. 
                cells.push({
                    columnKey: 'total',
                    rowKey: subType,
                    value: subTypeTotal,
                    data: {}, 
                })
            })

            // Add in the row for 'Others' 
            rowDefs.splice(rowDefIndex + Object.keys(txSubTypes).length + 1, 0, {key: 'Other ' + txType});
            cells.push({
                columnKey: 'rowLabel',
                rowKey: 'Other ' + txType,
                value: 'Other ' + txType,
                style: {paddingLeft: 20},
                data: {}
            });

            let OtherTotal = 0; 
            Object.entries(subTypeTotals).forEach(([column, value]) => {
                // Get the value of this type. 
                const typeValue = cells.find(cell => cell.rowKey === txType && cell.columnKey === column)?.value??0; 

                cells.push({
                    columnKey: column,
                    rowKey: 'Other ' + txType,
                    value: typeValue - value,
                    data: {}
                });
                OtherTotal += typeValue - value;
            });
            cells.push({
                columnKey: 'total',
                rowKey: 'Other ' + txType,
                value: OtherTotal,
                data: {}
            })
        })

        
        this.setState({columnDefs, rowDefs, headers, cells: cells}); 
    }

    fetchInvestmentProviders = async() => {
        const res = await api.get('/tpa/investmentProvider/list');
        if (!res?.success || !res?.investmentProviders)
            return this.setState({ investmentProviders: [] });
        this.setState({
            investmentProviders: res.investmentProviders
        });
    }

    saveAccount = async (item) =>{
        this.setState({isSaving: true});

        let account = {...this.state.editingAccount};
        let accounts = [...this.state.accounts];

        const res = await api.post('/tpa/account/update', {
            id: item._id,
            accountDiff: {
                ...this.state.editingAccount
            }
        });

        if(!res.success)
            return toast.error("Failed to update the account");

        accounts = accounts.map(a => {
            if(a._id !== item._id)
                return a; 
            return {...a, ...account};
        });

        this.setState({accounts, editingAccount: null, overflowId: null, isSaving: false}, () => {
            this.filterAccounts(); // May have set a property that does not fit within the filter.. 
        });
    }

    fetchTransactions = async() => {    
        const res = await api.post("/tpa/transaction/list", {
            planYear: this.props.planYear, 
            statementIds: this.state.statements.map(s => s._id),
            resultLimit: 10000,
        });

        let transactions = res.transactions??[]; 

        // Sort the transactions... 
        transactions = transactions.sort((a, b) => {
            const aDate = new Date(a.date); 
            const bDate = new Date(b.date);
            return aDate - bDate;
        });

        this.setState({transactions: [...transactions]});
    }

    reconciles = (statement) => {
        if(statement.confirmed)
            return true; 

        const {beginningBalance:beginning, endingBalance:ending} = statement; 
        const {
            deposits, 
            withdrawals, 
            income, 
            fees, 
            transfers, 
            reinvestments,
            other, 
            gain_loss,
            loanPayments, 
            rollovers,
        } = statement.tx;

        for (const lhs of [
            // beginning + deposits + withdrawals + income + fees + other + transfers + gain + accrued,
            beginning + deposits + withdrawals + income + fees + other + transfers + gain_loss + reinvestments + loanPayments + rollovers,
        ]) {
            try {
                if (lhs.toFixed(2).replace('-0', '0') === ending.toFixed(2).replace('-0', '0'))
                    return true;
            } catch {}
        }
    
        return false; // Failed to reconcile
    }

    applyStatementDiff = (statements) => {
        // Get the statements... 
        statements = [...statements].map(s => {
            const txDetails = {...s.tx}; 
            const diff = {...s.diff}; 

            // We need to apply the diff to the statement...
            Object.keys(diff).forEach((key) => {
                txDetails[key] = (txDetails[key]??0) + diff[key];
            });

            s = {
                ...s, tx: txDetails
            };

            // Lets double check if it reconciles. We may have made changes, and need to take everything into account.  
            s.reconciles = this.reconciles(s) 

            return {...s, tx: txDetails}; 
        });

        return statements; 
    }

    formatValue = (v) => {
        if(!v) return '-';
        const value = typeof v === 'number'? v? v>0? v.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }): `(${(v * -1).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })})`: '-': v;

        return value; 
    }  

    formatAccountsToCells = () => {
        const labelMapping = {
            beginningBalance: 'Opening Balance',
            deposits: 'Deposits',
            withdrawals: 'Withdrawals',
            loanPayments: 'Loan Payments',
            income: 'Income',
            fees: 'Fees',
            transfers: 'Transfers',
            rollovers: 'Rollovers',
            reinvestments: 'Reinvestments',
            other: 'Other Transactions',
            gain_loss: 'Gain/(loss)',
            endingBalance: 'Closing Balance',
            accrued: 'Accrued Income',
            balanceAccrued: 'Balance + Accruals'
        };

        // All possible months for trust accounting, assuming individual statements for each. 
        let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']; 
        // Sort the months based on plan year end... 
        let startMmIndex = (this.state.plan.endMm??12) % 12;
        months = [...months.slice(startMmIndex), ...months.slice(0, startMmIndex)];

        const accounts = [...this.state.accounts];

        let subTypes = {};

        const accountCells = accounts.reduce((accumulator, account) => {
            // We need to simplify the statements, and find what best covers all the months,
            // - Start by sorting shortest to longest coverage... 
            let statements = this.state.statements.filter(s => s.accountNumber === account.number);

            statements = this.applyStatementDiff([...(statements??[])]).sort((a, b) => {
                if(a.months.length !== b.months.length)
                    return a.months.length - b.months.length;
                else 
                    return months.indexOf(a.months[0]) - months.indexOf(b.months[0]); 
            }); 

            // - Find the best statement coverage... 
            statements = statements.reduce((acc, statement, index) => {
                let statementMonths = statement.months??[];  
                let columnKey = statementMonths.length > 1 ? `${statementMonths[0]} - ${statementMonths[statementMonths.length - 1]}` : statementMonths[0];

                // Is this already covered? (Check for a duplicate statements) (This should have been resolved on April 10th, 2024) 
                if(acc.some(s => s.columnKey === columnKey))
                    return acc;  

                // Do we have smaller statements that cover this statement? use the smaller ones... 
                const accMonths = acc.reduce((months, s) => [...months, ...s.months], []); 
                if(statementMonths.every(m => accMonths.includes(m)))
                    return acc; 

                // Sort the statement months...
                statementMonths.sort((a, b) => months.indexOf(a) - months.indexOf(b));
                
                if(!statementMonths.reduce((acc, m, i) => {
                    if(!acc.continue) return acc; 
                    // Do we have a different statement to cover this month? 
                    const idx = statements.find(s => s.months.includes(m) && s._id !== statement._id);
                    if(!acc.previous && idx) return {continue: false, previous: true};
                    return {continue: true, previous: idx? true: false};
                }, {continue: true, previous: true}).continue)
                    return acc;

                // We need to look at the ref's to figure out how to combine this... 
                // - LPL For example, may be a single feb statement, but cover jan - feb.  

                const statementRefs = {...statement.ref, ...statement.ref?.tx, tx: ""};  
                // Do we need to split this statement? 

                const isQuarterly = Object.values(statementRefs).some(r => r.includes("Quarter"));
                const isMulti = statementMonths.length > 1; 
                const split = (
                    (isQuarterly || isMulti) && !statement.split
                ); 

                if(split){
                    // What does this statement really cover? For example, if ref is mixed, or quartetly, and the statement is feb, it covers jan - feb. 
                    if(isQuarterly && !isMulti){
                        const standardMonths = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
                        // Find the index of the month in the standard months...
                        const monthIndex = standardMonths.indexOf(statementMonths[0]); 
                        const quarter = Math.ceil((monthIndex + 1) / 3); 
                        // Get all months within the quarter, upto the month.
                        statementMonths = standardMonths.slice(quarter * 3 - 3, monthIndex + 1);
                    }
                    
                    // Are we covering multiple months? Try to break down this statement as much as possible. 
                    const newCoverage = [...statementMonths.filter(m => !accMonths.includes(m))];
                    if(statementMonths.length > 1){
                        statementMonths.reduce((c, m) => {
                            if(!c.continue) return c; 
                            // Do we have  a statement that covers this month?  
                            const s = acc.find(s => s.months.includes(m)); 
                            if(!s) return {continue:false, statement: null};
                            if(c.statement === s._id)return c; 
                            // Split this statement... 
                            statement.original = {...statement, ...statement.tx}
                            statement.beginningBalance = s.endingBalance; 
                            // We need to adjust the other fields in the statement... 
                            Object.keys(labelMapping).forEach((key) => {
                                // We need to skip some fields...  
                                if(['beginningBalance', 'endingBalance', 'gain_loss'].includes(key)) return; // Gain loss will be calculated later. 

                                // Check the ref, if it is Since last statement, keep the value. else, subtract. 
                                if(statementRefs[key] && statementRefs[key].includes('Since last statement')){
                                    return; 
                                };
                                statement.tx[key] = (statement.tx[key]??0) - (s[key]??0); 
                                statement.calculated = {
                                    ...statement.calculated??{},
                                    [key]: true
                                }
                            });

                            statement.months = newCoverage; 
                            statement.split = true; 
                            statement.splitFrom = [...new Set([...statement.splitFrom??[], s.months])];
                            return {continue: true, statement: s._id};
                        }, {continue:true, statement: null});
                    }

                    // Update the column key... 
                    columnKey = newCoverage.length > 1 ? `${newCoverage[0]} - ${newCoverage[newCoverage.length - 1]}` : newCoverage[0]; 
                }

                if(statement.split) // If we modified the statement, we need to update the gain loss. 
                    statement.tx.gain_loss = this.calculatedGainLoss(statement.endingBalance, statement.beginningBalance, statement.tx);

                // Add the columnKey, and the rest of the statement to the accumalation. 
                return [...acc, {
                    ...statement, 
                    ...statement.tx, // Flatten the statement and bring transactions to the root level.
                    balanceAccrued: (statement.endingBalance??0) + (statement.tx.accrued??0), 
                    columnKey, 
                }]
            }, []);

            // Now we need to build the headers, (as keys) and include any months that are not covered by statements...
            let headers = [
                ...statements.map(s => s.columnKey), 
                ...months.filter(m => !statements.some(s => s.months.includes(m)))
            ].sort((a, b) => months.indexOf(a.split(" - ")[0]) - months.indexOf(b.split(" - ")[0]));

            // Sort the statements... 
            statements = statements.sort((a, b) => headers.indexOf(a.columnKey) - headers.indexOf(b.columnKey));

            const rowDefs = [
                {
                    key: 'beginningBalance', 
                    style:{
                        borderTop: '1px solid var(--color-border-light)', 
                        borderBottom: '1px solid var(--color-border-light)',
                        height: 50,
                    }
                },
                {key: 'deposits'},
                {key: 'withdrawals'},
                {key: 'income'},
                {key: 'fees'},
                {   
                    key: 'transfers',
                    style:{
                        borderBottom: '1px solid var(--color-border-light)',
                    }
                },
                {key: 'loanPayments'},
                {key: 'rollovers'},
                {key: 'reinvestments'},
                {key: 'other'},
                {
                    key: 'gain_loss',
                    style:{
                        borderTop: '1px solid var(--color-border-light)', 
                        borderBottom: '1px solid var(--color-border-light)'
                    },
                },
                {key: 'endingBalance'},
                {key: 'accrued'},
                {
                    key: 'balanceAccrued',
                    style:{
                        borderTop: '1px solid var(--color-border)', 
                        borderBottom: '6px double var(--color-border)'
                    },
                },
            ];

            const columnDefs = [
                {
                    key: 'rowLabel',
                    style: {
                        fontWeight: 600,
                        position: 'sticky',
                        left: 0,
                        backgroundColor: 'var(--color-background)',
                    }
                },
                ...headers.map(h => ({
                    key: h, 
                    style: {
                        alignItems: 'end',
                    },
                })),
                {
                    key: 'recievables',
                    style: {
                        alignItems: 'end',
                    }
                },
                {
                    key: 'total', 
                    style: {
                        position: 'sticky',
                        right: 0,
                        backgroundColor: 'var(--color-background)',
                        alignItems: 'end'
                    }
                }
            ];

            headers = columnDefs.map(def => {
                let value = def.key?.toUpperCase(); 
                if(def.key === 'rowLabel') value = '';
                if(def.key === 'total') value = 'Total';
                if(def.key === 'recievables') value = 'Recievables';

                let data = {
                    missing: true, 
                    planYear: this.props.planYear, 
                    account: {...this.state.account}
                }

                if(def.key === 'rowLabel' || def.key === 'total' || def.key === 'recievables') data = null; 

                return {
                    columnKey: def.key, 
                    value, 
                    data,
                }

            });


            const cells = statements.reduce((acc, statement, statementIdx) => {
                // What is the column key for this statement (Month it belongs to) 
                const columnKey = statement.columnKey; // Set above, while cleaning the statements. 

                // We need to attach data from this statement to the header. This will allow for actions (Review, approve, etc...) 
                const headerIdx = headers.findIndex(h => h.columnKey === columnKey);
                const header = headers[headerIdx];
                header.data.errorCount = 0; 
                header.data.missing = false; 
                header.data.statement = statement._id;  
                header.data.confirmed = statement.confirmed;
                header.data.reconciles = statement.reconciles;
                header.data.document = statement.document;
                header.data.split = statement.split; 
                header.data.splitFrom = statement.splitFrom??[];

                const beginningBalance = statement.beginningBalance??0;
                const endingBalance = statement.endingBalance??0;
                
                // Run a check to update headers for months we are not expecting. If we do not expect it, mark it as na...  
                if(beginningBalance === 0){
                    // By default, if something is missing, it will be marked as so. We need to check if we should switch it to NA. 
                    // Find the first header with missing being set to true
                    const missingIdx = headers.findIndex(h => h.data?.missing === true); 
                    if(missingIdx === 1){
                        for(let i = 1; i < headerIdx; i++){
                            headers[i].data.missing = false; 
                            headers[i].data.na = true; 
                        }
                    }
                }

                if(endingBalance === 0 && statementIdx === statements.length -1){ // This is the last statement we have, and it ends at 0. We dont care about the rest.  
                    for(let i = headerIdx + 1; i < headers.length - 2; i++){
                        headers[i].data.missing = false; 
                        headers[i].data.na = true; 
                    }
                }

                // For each row, build the cell that belong's to it. 
                const statementCells = rowDefs.map((rowDef, index) => {
                    const confidence = {...statement.confidence, ...statement.confidence.tx}?.[rowDef.key]??1; 
                    const cell = {
                        document: statement.document,
                        columnKey: statement.columnKey, 
                        rowKey: rowDef.key,
                        value: (statement[rowDef.key]??0),
                        data: {
                            confidence: confidence,
                        },
                    }

                    return cell; 
                });

                const statementTransactions = this.state.transactions?.filter(t => {
                    return (t.statement === statement._id || (statement.statements??[])?.includes(t.statement))
                });

                const txMapping = {
                    Deposit: 'Deposits',
                    Withdrawal: 'Withdrawals',
                }

                let subTypeCells = [];
                if (statementTransactions) 
                    subTypeCells = statementTransactions.reduce((accSubCell, t) => {
                        if(!t.txSubType) return accSubCell; 

                        const type = t.txType; 
                        const subType = t.txSubType;
                        const amount = t.amount;  
                        const rowDef = Object.entries(helpers.transactionTypeMapping).find(([key, value]) => value === type)?.[0]; 

                        // add the type and subtype to the subTypes object
                        if (!subTypes[type] || !subTypes[type].includes(subType)) {
                            if (Object.keys(txMapping).includes(type)) {
                                subTypes[txMapping[type]] = [...(subTypes[txMapping[type]]??[]), subType];
                            } else {
                                subTypes[type] = [...(subTypes[type]??[]), subType];

                            }
                        }

                        const confidence = {...statement.confidence, ...statement.confidence.tx}?.[rowDef.key]??1; 
                        const subCell = {
                            document: statement.document,
                            columnKey: statement.columnKey, 
                            rowKey: subType,
                            value: (amount??0),
                            data: {
                                confidence: confidence,
                            },
                        }
                        return [...accSubCell, subCell ];
                    }, []);


                // Add the statement cells to the accumalation... 
                return [...acc, ...statementCells, ...subTypeCells]; 
            }, rowDefs.map(def => ({columnKey: 'rowLabel', rowKey: def.key, value: labelMapping[def.key], data: {}}))); // An intial array of cells for the row headers... 

            const number = String(account.number);
            return {...accumulator, [number] : [...cells]}
        }, {});

        this.setState({accountCells, subTypes});
    }

    removeAllFormulas = async (workbook) => {
        workbook.eachSheet((worksheet) => {
          worksheet.eachRow((row) => {
            row.eachCell((cell) => {
              if (cell.type === Excel.ValueType.Formula) {
                // Optionally capture the current value before removing the formula
                const currentValue = cell.value.result;
                
                // Remove the formula and set the value
                cell.value = null;  // Remove the formula
                cell.value = currentValue;  // Set the value to the previous result of the formula
              }
            });
          });
        });
    }

    editAccountSheet =  (account, accountWorksheet) => {
        let monthMapping = {
            Jan: 8, 
            Feb: 9, 
            Mar: 10, 
            Apr: 11, 
            May: 12, 
            Jun: 13, 
            Jul: 14, 
            Aug: 15, 
            Sep: 16, 
            Oct: 17, 
            Nov: 18, 
            Dec: 19
        }

        let newOrderNames = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
        // if the plan year ends in a month other than December, we need to adjust the monthMapping
        if (this.state.plan.endMm !== 12) {
            const monthNames = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
            let startMmIndex = (this.state.plan.endMm??12) % 12;
            newOrderNames = [...monthNames.slice(startMmIndex), ...monthNames.slice(0, startMmIndex)];  
            monthMapping = newOrderNames.reduce((acc, month, index) => {
                // Assuming you want the index to start at 8 and wrap around after reaching 12
                acc[month] = index + 8;
                return acc;
            }, {});
        }

        const metadataMapping = {
            beginningBalance: 'C',
            deposits: 'G',
            withdrawals: 'H',
            loanPayments: 'I',
            income: 'J',
            fees: 'K',
            transfers: 'L',
            other: 'O',
            gain_loss: 'Q',
            endingBalance: 'D',
            accrued: 'E',
            // balanceAccrued: 'F',
            // purchases: 'purchases',
            // sales: 'sales',
            rollovers: 'M',
            reinvestments: 'N',
        }

        const txMapping = {
            Deposit: 'Deposits',
            Withdrawal: 'Withdrawals',
        }

        let cell = accountWorksheet.getCell('B3');
        cell.value = account.number;

        cell = accountWorksheet.getCell('B4');
        cell.value = account.payee;

        cell = accountWorksheet.getCell('B5');
        cell.value = account.provider;

         // if off PYE, re-write the months
        if (this.state.plan.endMm !== 12) {
            Object.entries(monthMapping).forEach(([month, index]) => {
                accountWorksheet.getCell(`A${index}`).value = month;
            });
        }

        let rowsToRemove = {};

        const editableCells = this.state.accountCells[account.number].filter(cell => cell.columnKey !== 'total' && cell.columnKey !== 'rowLabel');
        
        editableCells.forEach( cell => {

            if (['balanceAccrued'].includes(cell.rowKey)) return;
            let cellKey;
            const month = cell.columnKey.includes(' - ') ? cell.columnKey.split(' - ') : cell.columnKey;
            // monthly statements
            if (!Array.isArray(month)) {

                if (metadataMapping[cell.rowKey] && monthMapping[cell.columnKey]) {
                    cellKey = metadataMapping[cell.rowKey] + monthMapping[cell.columnKey];
                    accountWorksheet.getCell(cellKey).value = cell.value;

                    accountWorksheet.getCell(`B${monthMapping[cell.columnKey]}`).value = {
                        text: 'Open Stmt',
                        hyperlink: `https://my.stax.ai/document/${cell.document}`,                
                    }  

                } else if (!metadataMapping[cell.rowKey]) {

                    const type = Object.entries(this.state.subTypes).find(([key, value]) => value.includes(cell.rowKey))[0];
                    if (!type) return;

                    const indice = metadataMapping[type.toLowerCase()].charCodeAt(0) - 65 + 1;

                    Object.entries(metadataMapping).forEach(([key, value]) => {
                        if (value.charCodeAt(0) - 65 + 1 <= indice) return;

                        metadataMapping[key] = String.fromCharCode(value.charCodeAt(0) + 1);
                    });

                    metadataMapping[cell.rowKey] = String.fromCharCode(indice + 65 - 1 + 1);
                    accountWorksheet.spliceColumns(indice+1, 0, [...new Array(6).fill(null),`${cell.rowKey[0].toUpperCase()}${cell.rowKey.slice(1)}`]);

                    accountWorksheet.getCell( 7, indice+1 ).font = {bold: true};
                    accountWorksheet.getCell( 7, indice+1 ).border = {bottom: {style: 'thick'}};

                    for (let i = 8; i < 20; i++) {
                        accountWorksheet.getCell(i, indice+1).numFmt = JSON.parse(JSON.stringify(accountWorksheet.getCell(8, indice).numFmt));
                        accountWorksheet.getCell(i, indice+1).value = 0;
                    }

                    // if other type column is not in the mapping, we need to add it
                    if (!metadataMapping[`Other ${type}`]) {
                        const indiceOther = indice + 1;
                        // shifting all columns that are to the right of the new column
                        Object.entries(metadataMapping).forEach(([key, value]) => {
                            if (value.charCodeAt(0) - 65 + 1 <= indiceOther) return;

                            metadataMapping[key] = String.fromCharCode(value.charCodeAt(0) + 1);
                        });

                        metadataMapping[`Other ${type}`] = String.fromCharCode(indiceOther + 65 - 1 + 1);
                        accountWorksheet.spliceColumns(indiceOther + 1, 0, [...new Array(6).fill(null),`Other ${type}`]);
                        // styling the new column
                        accountWorksheet.getCell(7, indiceOther + 1).font = {bold: true};
                        accountWorksheet.getCell( 7, indiceOther + 1 ).border = {bottom: {style: 'thick'}};

                        for (let i = 8; i < 20; i++) {
                            accountWorksheet.getCell(i, indiceOther+1).numFmt = JSON.parse(JSON.stringify(accountWorksheet.getCell(8, indiceOther).numFmt));
                            accountWorksheet.getCell(i, indiceOther+1).value = 0;
                        }

                    }

                    cellKey = metadataMapping[cell.rowKey] + monthMapping[month];
    
                    accountWorksheet.getCell(cellKey).value = cell.value;

                    cellKey = metadataMapping[`Other ${type}`] + monthMapping[month];
                    // set the value as the difference of the main type minus the summation of its subtypes
                    accountWorksheet.getCell(cellKey).value = accountWorksheet.getCell(cellKey)?.value??0 + (editableCells.find(c => c.columnKey === cell.columnKey && c.rowKey === type.toLowerCase()).value - cell.value);
                
                }
            } else {
                // multi months statement
                const startMonth  = month[0];
                const endMonth = month[1];

                accountWorksheet.getCell(`A${monthMapping[startMonth]}`).value = cell.columnKey;
                accountWorksheet.getCell(`B${monthMapping[startMonth]}`).value = {
                    text: 'Open Stmt',
                    hyperlink: `https://my.stax.ai/document/${cell.document}`,                
                }

                
                if (!metadataMapping[cell.rowKey]) {
                    const type = Object.entries(this.state.subTypes).find(([key, value]) => value.includes(cell.rowKey))[0];

                    if (!type) return;

                    const indice = metadataMapping[type.toLowerCase()].charCodeAt(0) - 65 + 1;
                    // shifting all columns that are to the right of the new column
                    Object.entries(metadataMapping).forEach(([key, value], idx) => {
                        if (value.charCodeAt(0) - 65 + 1 <= indice) return;

                        metadataMapping[key] = String.fromCharCode(value.charCodeAt(0) + 1);
                    });

                    metadataMapping[cell.rowKey] = String.fromCharCode(indice + 65 - 1 + 1);
                    accountWorksheet.spliceColumns(indice+1, 0, [...new Array(6).fill(null),`${cell.rowKey[0].toUpperCase()}${cell.rowKey.slice(1)}`]);
                    // styling
                    accountWorksheet.getCell(7, indice + 1).font = {bold: true};
                    accountWorksheet.getCell( 7, indice + 1 ).border = {bottom: {style: 'thick'}};

                    for (let i = 8; i < 20; i++) {
                        accountWorksheet.getCell(i, indice+1).numFmt = JSON.parse(JSON.stringify(accountWorksheet.getCell(8, indice).numFmt));
                        accountWorksheet.getCell(i, indice+1).value = 0;
                    }
                    // if other type column has not been created, we need to add it
                    if (!metadataMapping[`Other ${type}`]) {
                        const indiceOther = indice + 1;

                        Object.entries(metadataMapping).forEach(([key, value], idx) => {
                            if (value.charCodeAt(0) - 65 + 1 <= indiceOther) return;

                            metadataMapping[key] = String.fromCharCode(value.charCodeAt(0) + 1);
                        });

                        metadataMapping[`Other ${type}`] = String.fromCharCode(indiceOther + 65 - 1 + 1);
                        accountWorksheet.spliceColumns(indiceOther + 1, 0, [...new Array(6).fill(null),`Other ${type}`]);
                        // styling
                        accountWorksheet.getCell(7, indiceOther + 1).font = {bold: true};
                        accountWorksheet.getCell( 7, indiceOther + 1 ).border = {bottom: {style: 'thick'}};

                        for (let i = 8; i < 20; i++) {
                            accountWorksheet.getCell(i, indiceOther+1).numFmt = JSON.parse(JSON.stringify(accountWorksheet.getCell(8, indiceOther).numFmt));
                            accountWorksheet.getCell(i, indiceOther+1).value = 0;
                        }
                    }

                    cellKey = metadataMapping[cell.rowKey] + monthMapping[startMonth];
    
                    accountWorksheet.getCell(cellKey).value = cell.value;

                    cellKey = metadataMapping[`Other ${type}`] + monthMapping[startMonth];
                    // set the value as the difference of the main type minus the summation of its subtypes
                    accountWorksheet.getCell(cellKey).value = accountWorksheet.getCell(cellKey)?.value??0 + (editableCells.find(c => c.columnKey === cell.columnKey && c.rowKey === type.toLowerCase()).value - cell.value);
                
                } else {
                    cellKey = metadataMapping[cell.rowKey] + monthMapping[startMonth];
                    accountWorksheet.getCell(cellKey).value = cell.value;
                }

                for (let i = monthMapping[startMonth] + 1; i <= monthMapping[endMonth]; i++) {
                    if (!rowsToRemove[startMonth]) 
                        rowsToRemove[startMonth] = [];
                    rowsToRemove[startMonth].push(i);
                }
                rowsToRemove[startMonth] = [...new Set(rowsToRemove[startMonth])];
            }
            accountWorksheet.getCell([cellKey]).style = JSON.parse(JSON.stringify(accountWorksheet.getCell([cellKey]).style));
            if (cell.rowKey === 'other' && accountWorksheet.getCell(cellKey).value !== 0) {
                accountWorksheet.getCell([cellKey]).fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFC000' }, 
                };

            } else if (cell?.data?.confidence >= 0 && cell?.data?.confidence < 0.95 && cell?.data?.confidence > 0.8) {
                accountWorksheet.getCell([cellKey]).fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'BF8F00' }, 
                };
            
            } else if (cell?.data?.confidence >= 0 && cell?.data?.confidence < 0.8 && cell?.data?.confidence > 0.6) {
                accountWorksheet.getCell([cellKey]).fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'C65911' }, 
                };

            } else if (cell?.data?.confidence >= 0 && cell?.data?.confidence < 0.6) {
                accountWorksheet.getCell([cellKey]).fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'C00000' }, 
                };
            }
        });

        let totalRowsRemoved = 0;
        // sort rows to remove by descending month  
        const reversedMonthOrder = newOrderNames.reverse();
        const sortedEntries = Object.entries(rowsToRemove).sort((a, b) => reversedMonthOrder.indexOf(a[0]) - reversedMonthOrder.indexOf(b[0]));

        sortedEntries.forEach(([start, removing]) => {
            accountWorksheet.spliceRows(monthMapping[start] + 1, removing.length);
            totalRowsRemoved += removing.length;
        });

        // store months from account sheet into list
        let months = {};
        if (totalRowsRemoved > 0) {
            for (let i = 8; i <= 19 - totalRowsRemoved; i++) {
                months[accountWorksheet.getCell(`A${i}`).value] = i;
            }
        } else {
            months = {...monthMapping};
        }

        let formulaeIdx = 19;
        // gain/loss column index
        const columnGL = String.fromCharCode(metadataMapping.gain_loss.charCodeAt(0) - 1);
        // re-adding formulae for G/L and other type columns
        for (let i = 8; i <= 19 - totalRowsRemoved; i++) {
            let subTypes = {};
            const subs = Object.keys(metadataMapping).length > 13 ? 
                Object.entries(metadataMapping).reduce((acc, [label, letter]) => {
                    if (Object.keys(helpers.labelMapping).includes(label)) return acc;
                    subTypes[label] = letter;
                    return acc + `+${letter}${i}`;
                
                }, '') : 
                ''
            ;

            accountWorksheet.getCell(`${columnGL}${i}`).value = {
                formula: `=D${i}-C${i}-SUM(${metadataMapping.deposits}${i}:${metadataMapping.other}${i})` + subs,
            }
            accountWorksheet.getCell(`F${i}`).value = {
                formula: `=SUM(D${i}:E${i})`
            }
            
            const rowsRemovedCount = totalRowsRemoved; 
            Object.entries(subTypes).forEach(([label, letter]) => {
                if (!label.includes('Other ')) return;

                const type = label.split(' ')[1];
                const subTypeCells = this.state.subTypes[type].reduce((acc, label) => {

                    return acc + `-${metadataMapping[label]}${i}`;
                }, '');

                accountWorksheet.getCell(`${metadataMapping[label]}${i}`).value = {
                    formula: `=${metadataMapping[type.toLowerCase()]}${i}${subTypeCells}`
                };

                accountWorksheet.getCell(`${metadataMapping[label]}${ 19 - rowsRemovedCount + 2}`).value = {
                    formula: `=${metadataMapping[type.toLowerCase()]}${ 19 - rowsRemovedCount + 2}${subTypeCells.replace(i,  19 - rowsRemovedCount + 2)}`
                };
                accountWorksheet.getCell(`${metadataMapping[label]}${ 19 - rowsRemovedCount + 2}`).style = JSON.parse(JSON.stringify(accountWorksheet.getCell(`G${19 - rowsRemovedCount + 2}`).style));
            }, '');

            Object.keys(this.state.subTypes).forEach(type => {
                const column = metadataMapping[type.toLowerCase()] ?? metadataMapping[type];
                accountWorksheet.getCell(`${column}${i}`).style = JSON.parse(JSON.stringify(accountWorksheet.getCell(`${column}${i}`).style));
                accountWorksheet.getCell(`${column}${i}`).font = {color: {argb: 'A6A6A6'}};
            });
            
        }
        // totals 
        formulaeIdx = 20 - totalRowsRemoved;
        let formulae = Object.values(metadataMapping).map(letter => `${letter}${formulaeIdx-1}`);
        // setting totals formulae
        formulae.forEach(cell => {
            let letter = cell.split('')[0];

            if (letter === 'C') {
                return accountWorksheet.getCell(`${letter}${formulaeIdx}`).value = {
                    formula: `=C8`
                }
            } else if (letter === 'D') {
                return accountWorksheet.getCell(`${letter}${formulaeIdx}`).value = {
                    formula: `=D${formulaeIdx-1}`
                
                }
            }
            let index = parseInt(cell.substring(1)) + 1;
            accountWorksheet.getCell(`${letter}${index}`).value = {
                formula: `=SUM(${letter}8:${cell})`
            }

            accountWorksheet.getCell(`${letter}${index}`).style = JSON.parse(JSON.stringify(accountWorksheet.getCell(`C${formulaeIdx}`).style));
        });
        // adding calculated GL as it is not in the mapping
        accountWorksheet.getCell(`${columnGL}${formulaeIdx}`).value = {
            formula: `=SUM(${columnGL}8:${columnGL}${formulaeIdx-1})`
        }
        // transaction check by type
        formulaeIdx = formulaeIdx + 1;
        if (this.props.team?.ta?.txDetail !== 'None') {
            Object.entries(metadataMapping).forEach(([key, value]) => {
                if (['deposits', 'withdrawals', 'loanPayments', 'income', 'fees', 'transfers', 'other', 'rollovers', 'reinvestments'].includes(key)) {
                    accountWorksheet.getCell(`${value}${formulaeIdx}`).value = {
                        formula: `=SUMIF($A$${26 - totalRowsRemoved}:$A$100000,${value}7,$M$${26 - totalRowsRemoved}:$M$100000)`
                    }
                    accountWorksheet.getCell(`${value}${formulaeIdx}`).style = JSON.parse(JSON.stringify(accountWorksheet.getCell(`G${formulaeIdx}`).style));
                }
                else if (!['gain_loss', 'beginningBalance', 'endingBalance', 'accrued'].includes(key) && !key.includes('Other ')) {
                    accountWorksheet.getCell(`${value}${formulaeIdx}`).value = {
                        formula: `=SUMIF($B$${26 - totalRowsRemoved}:$B$100000,${value}7,$M$${26 - totalRowsRemoved}:$M$100000)`
                    }
                    accountWorksheet.getCell(`${value}${formulaeIdx}`).style = JSON.parse(JSON.stringify(accountWorksheet.getCell(`G${formulaeIdx}`).style));
                }
            });
        }

        // transaction totals
        formulaeIdx = formulaeIdx + 2;
        accountWorksheet.getCell(`J${formulaeIdx}`).value = 'Transaction Totals:';
        accountWorksheet.getCell(`J${formulaeIdx}`).font = { bold: true};
        formulae = [
            `=SUM($K$${26 - totalRowsRemoved}:$K$100000)`, 
            `=SUM($L$${26 - totalRowsRemoved}:$L$100000)`, 
        ];
        accountWorksheet.getCell(`K${formulaeIdx}`).value = {
            formula: formulae[0]
        };
        accountWorksheet.getCell(`K${formulaeIdx}`).style = JSON.parse(JSON.stringify(accountWorksheet.getCell(`K${formulaeIdx}`).style));
        accountWorksheet.getCell(`K${formulaeIdx}`).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'DDDDDD' },
        }
        accountWorksheet.getCell(`K${formulaeIdx}`).font = {
            bold: true,
        }

        accountWorksheet.getCell(`L${formulaeIdx}`).value = {
            formula: formulae[1]
        };
        accountWorksheet.getCell(`L${formulaeIdx}`).style = JSON.parse(JSON.stringify(accountWorksheet.getCell(`L${formulaeIdx}`).style));
        accountWorksheet.getCell(`L${formulaeIdx}`).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'DDDDDD' },
        }
        accountWorksheet.getCell(`L${formulaeIdx}`).font = {
            bold: true,
        }

        // auto filter
        accountWorksheet.autoFilter = undefined;
        accountWorksheet.autoFilter = `A${26 - totalRowsRemoved - 1}:M${26 - totalRowsRemoved - 1}`;
        // loop through transactions
        const transactions = [...this.state.transactions].filter(t => t.accountNumber === account.number);
        // sort transactions by date
        transactions.sort((a, b) => {
            const dateA = new Date(a.date);
            const dateB = new Date(b.date);
            return dateA - dateB;
        });
        // setting headers for transactions (SettleDate, Credits, Debits, Net)
        accountWorksheet.getCell(`J${26 - totalRowsRemoved - 1}`).value = 'Settle Date';
        accountWorksheet.getCell(`J${26 - totalRowsRemoved - 1}`).style = JSON.parse(JSON.stringify(accountWorksheet.getCell(`A${26 - totalRowsRemoved - 1}`).style));

        accountWorksheet.getCell(`K${26 - totalRowsRemoved - 1}`).value = 'Credits';
        accountWorksheet.getCell(`K${26 - totalRowsRemoved - 1}`).style = JSON.parse(JSON.stringify(accountWorksheet.getCell(`A${26 - totalRowsRemoved - 1}`).style));

        accountWorksheet.getCell(`L${26 - totalRowsRemoved - 1}`).value = 'Debits';
        accountWorksheet.getCell(`L${26 - totalRowsRemoved - 1}`).style = JSON.parse(JSON.stringify(accountWorksheet.getCell(`A${26 - totalRowsRemoved - 1}`).style));

        accountWorksheet.getCell(`M${26 - totalRowsRemoved - 1}`).value = 'Net';
        accountWorksheet.getCell(`M${26 - totalRowsRemoved - 1}`).style = JSON.parse(JSON.stringify(accountWorksheet.getCell(`A${26 - totalRowsRemoved - 1}`).style));

        transactions.forEach((transaction, index) => {
            const txIdx = index + 26 - totalRowsRemoved;

            accountWorksheet.getCell(`A${txIdx}`).value = ["deposit", "withdrawal"].includes(transaction?.txType?.toLowerCase()) ? txMapping[transaction?.txType] : transaction?.txType;
            accountWorksheet.getCell(`B${txIdx}`).value = transaction?.txSubType;
            accountWorksheet.getCell(`C${txIdx}`).value = transaction?.description;
            // add hyperlink to statement on column I
            const documentId = this.state.statements.find(s => s !== transaction.statement)?.document;
            accountWorksheet.getCell(`I${txIdx}`).value = {
                text: 'View TX',
                hyperlink: `https://my.stax.ai/document/${documentId}?page=${transaction.page}`,                
            }
            accountWorksheet.getCell(`I${txIdx}`).font = { color: { argb: '00B0F0' }, bold: true};

            const date =  transaction?.date ? new Date(transaction?.date).toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
                timeZone: 'UTC'
              }) : new Date().toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
                timeZone: 'UTC'
              });

            accountWorksheet.getCell(`J${txIdx}`).value = date;
            accountWorksheet.getCell(`J${txIdx}`).numFmt = 'm/d/yyyy';

            transaction?.amount > 0 ? accountWorksheet.getCell(`K${txIdx}`).value = transaction?.amount : accountWorksheet.getCell(`L${txIdx}`).value = transaction?.amount;
            accountWorksheet.getCell(`K${txIdx}`).numFmt = '_(* #,##0.00_);_(* (#,##0.00);_(* -??_);_(@_)';
            accountWorksheet.getCell(`L${txIdx}`).numFmt = '_(* #,##0.00_);_(* (#,##0.00);_(* -??_);_(@_)';

            accountWorksheet.getCell(`K${txIdx}`).font = { color: { argb: '000000' }};
            accountWorksheet.getCell(`L${txIdx}`).font = { color: { argb: '000000' }};

            accountWorksheet.getCell(`M${txIdx}`).value = {
                formula: `=SUM(K${txIdx}:L${txIdx})`
            };
            accountWorksheet.getCell(`M${txIdx}`).style = JSON.parse(JSON.stringify(accountWorksheet.getCell(`M${txIdx}`).style));
            accountWorksheet.getCell(`M${txIdx}`).fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'DDDDDD' },
            }
            accountWorksheet.getCell(`M${txIdx}`).numFmt = '_(* #,##0.00_);_(* (#,##0.00);_(* -??_);_(@_)';
            // color rules to highlight transactions
            if (transaction?.confidence < 0.6) {
                accountWorksheet.getCell(`A${txIdx}`).style = JSON.parse(JSON.stringify(accountWorksheet.getCell(`A${txIdx}`).style));

                accountWorksheet.getCell(`A${txIdx}`).fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'C00000' }, 
                };

            } else if (transaction?.confidence < 0.8 && transaction?.confidence > 0.6) {
                accountWorksheet.getCell(`A${txIdx}`).style = JSON.parse(JSON.stringify(accountWorksheet.getCell(`A${txIdx}`).style));

                accountWorksheet.getCell(`A${txIdx}`).fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'C65911' }, 
                };

            } else if (transaction?.confidence < 0.95 && transaction?.confidence > 0.8) {
                accountWorksheet.getCell(`A${txIdx}`).style = JSON.parse(JSON.stringify(accountWorksheet.getCell(`A${txIdx}`).style));

                accountWorksheet.getCell(`A${txIdx}`).fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'BF8F00' }, 
                };
            }
        });
        // change the sheet tiltle to the account number and update the formulae on the first accountWorksheet
        accountWorksheet.name = `Acct# ${account.number}`;

        formulaeIdx = 19 - totalRowsRemoved;
        // conditional formatting
        const conditionalFormattingRuleBalances = {
            type: 'expression', 
            formulae: [`=FIXED(D8,2)<>FIXED(C9,2)`], 
            style: {
              fill: {
                type: 'pattern',
                pattern: 'solid',
                bgColor: { argb: 'FFEB84' },
              },
            },
        };
        const conditionalFormattingRuleGainLoss = {
            type: 'expression', 
            formulae: [`=FIXED($${columnGL}8,2)<>FIXED($${metadataMapping.gain_loss}8,2)`], 
            style: {
              fill: {
                type: 'pattern',
                pattern: 'solid',
                bgColor: { argb: '5B9BD5' },
              },
            },
        };

        if (this.props.team?.ta?.txDetail !== 'None') {
            const conditionalFormattingRuleTx = {
                type: 'expression', 
                formulae: [`=FIXED(G${formulaeIdx+1},2)<>FIXED(G${formulaeIdx+2},2)`], 
                style: {
                  fill: {
                    type: 'pattern',
                    pattern: 'solid',
                    bgColor: { argb: 'FFCCCC' },
                  },
                },
            };
            accountWorksheet.addConditionalFormatting({
                ref: `G${formulaeIdx+2}:${metadataMapping.other}${formulaeIdx+2}`, 
                rules: [conditionalFormattingRuleTx],
            });

        } else {
            accountWorksheet.getCell(`A${formulaeIdx+2}`).value = '';
        }

        accountWorksheet.addConditionalFormatting({
            ref: `C9:C${formulaeIdx}`, 
            rules: [conditionalFormattingRuleBalances],
        });
        accountWorksheet.addConditionalFormatting({
            ref: `${metadataMapping.gain_loss}8:${metadataMapping.gain_loss}${formulaeIdx}`, 
            rules: [conditionalFormattingRuleGainLoss],
        });

        const accountNumber = account.number;
        this.setState({accountMetaMapping: {...this.state.accountMetaMapping,  [accountNumber]: {...metadataMapping}}});
        return {...months};
    }

    exportPlanToExcel = async () => {
        this.props.loading(); 

        let monthMapping = {
            Jan: 7, 
            Feb: 8, 
            Mar: 9, 
            Apr: 10, 
            May: 11, 
            Jun: 12, 
            Jul: 13, 
            Aug: 14, 
            Sep: 15, 
            Oct: 16, 
            Nov: 17, 
            Dec: 18
        }

        let newOrderNames = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
        // if the plan year ends in a month other than December, we need to adjust the monthMapping
        if (this.state.plan.endMm !== 12) {
            const monthNames = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
            let startMmIndex = (this.state.plan.endMm??12) % 12;
            newOrderNames = [...monthNames.slice(startMmIndex), ...monthNames.slice(0, startMmIndex)];  
            monthMapping = newOrderNames.reduce((acc, month, index) => {
                // Assuming you want the index to start at 7 and wrap around after reaching 12
                acc[month] = index + 7;
                return acc;
            }, {});
        }
        const newOrderNamesOriginal = [...newOrderNames];

        const metadataMapping = {
            beginningBalance: 'C',
            deposits: 'G',
            withdrawals: 'H',
            loanPayments: 'I',
            income: 'J',
            fees: 'K',
            transfers: 'L',
            other: 'O',
            gain_loss: 'P',
            endingBalance: 'D',
            accrued: 'E',
            // balanceAccrued: 'F',
            // purchases: 'purchases',
            // sales: 'sales',
            rollovers: 'M',
            reinvestments: 'N',
        }

        const workbook = new ExcelJS.Workbook();
        const response = await fetch('/templates/TrustAccountingPlan.xlsx');
        const arrayBuffer = await response.arrayBuffer();

        await workbook.xlsx.load(arrayBuffer);
        this.removeAllFormulas(workbook);

        const  planWorksheet = workbook.getWorksheet(1); 
        const cells = this.state.cells.filter(cell => cell.columnKey !== 'total' && cell.columnKey !== 'rowLabel');

        let cell = planWorksheet.getCell('B3');
        cell.value = this.state.plan.planName;

        cell = planWorksheet.getCell('B4');
        cell.value = (this.state.plan.endMm < 10 ? "0" + this.state.plan.endMm : this.state.plan.endMm) + "/" + this.state.plan.endDd + "/" + this.props.planYear;

        // if off PYE, re-write the months
        if (this.state.plan.endMm !== 12) {
            Object.entries(monthMapping).forEach(([month, index]) => {
                planWorksheet.getCell(`A${index}`).value = month;
            });
        }

        let summaryFormulae = {};
        // make a copy of the `copy` sheet to create a new sheet for each account
        const accountSheet = workbook.getWorksheet('copy');
        const accounts = [...new Set(this.state.statements.map(s => s.accountNumber))];
        this.state.accounts.filter(a => accounts.includes(a.number)).forEach(account => {
            if (account?.accountType !== 'brokerage') return;
            if (workbook.getWorksheet(`Acct# ${account.number}`)) return;
            const newSheet = workbook.addWorksheet(`Acct# ${account.number}`);

            accountSheet.eachRow({ includeEmpty: true }, (row, rowNumber) => {
                const newRow = newSheet.getRow(rowNumber);
                row.eachCell({ includeEmpty: true }, (cell, colNumber) => {
                  newRow.getCell(colNumber).value = cell.value;
                  newRow.getCell(colNumber).style = cell.style;
                });
                newRow.commit();
            });

            summaryFormulae[account.number] = this.editAccountSheet(account, newSheet);

        });

        workbook.removeWorksheet('copy');

        // adding all the subtype columns to plan summary sheet
        Object.entries(this.state.subTypes).forEach(([type, subTypes]) => {
            const indice = metadataMapping[type.toLowerCase()].charCodeAt(0) - 65 + 1;
            subTypes.forEach(subType => {
                Object.entries(metadataMapping).forEach(([key, value], idx) => {
                    if (value.charCodeAt(0) - 65 + 1 <= indice) return;

                    metadataMapping[key] = String.fromCharCode(value.charCodeAt(0) + 1);
                });

                metadataMapping[subType] = String.fromCharCode(indice + 65 - 1 + 1);
                // insert new column with header
                planWorksheet.spliceColumns(indice+1, 0, [...new Array(5).fill(null),`${subType[0].toUpperCase()}${subType.slice(1)}`]);

                planWorksheet.getCell( 6, indice+1 ).font = {bold: true};
                planWorksheet.getCell( 6, indice+1 ).border = {bottom: {style: 'thick'}};

                // setting the style of the new column to the style of the first column
                for (let i=7; i < 19; i++) {
                    planWorksheet.getCell(`${metadataMapping[subType]}${i}`).style = JSON.parse(JSON.stringify(planWorksheet.getCell("C7").style));
                }
            });

            // adding the other column
            if (!metadataMapping[`Other ${type}`]) {
                const indiceOther = indice + subTypes.length;

                Object.entries(metadataMapping).forEach(([key, value], idx) => {
                    if (value.charCodeAt(0) - 65 + 1 <= indiceOther) return;

                    metadataMapping[key] = String.fromCharCode(value.charCodeAt(0) + 1);
                });

                metadataMapping[`other ${type.toLowerCase()}`] = String.fromCharCode(indiceOther + 65 - 1 + 1);
                planWorksheet.spliceColumns(indiceOther + 1, 0, [...new Array(5).fill(null),`Other ${type}`]);

                planWorksheet.getCell(6, indiceOther + 1).font = {bold: true};
                planWorksheet.getCell( 6, indiceOther + 1 ).border = {bottom: {style: 'thick'}};

                for (let i=7; i < 19; i++) {
                    planWorksheet.getCell(i, indiceOther + 1).style = JSON.parse(JSON.stringify(planWorksheet.getCell("C7").style)); 
                }
            }
        });

        let rowsToRemove = {};
        cells.forEach(async cell => {
            if (['balanceAccrued'].includes(cell.rowKey)) return;
            let cellKey;
            const month = cell.columnKey.includes(' - ') ? cell.columnKey.split(' - ') : cell.columnKey;

            // monthly statements
            if (!Array.isArray(month)) {

                if (metadataMapping[cell.rowKey] && monthMapping[cell.columnKey]) {
                    cellKey = metadataMapping[cell.rowKey] + monthMapping[cell.columnKey];
                    planWorksheet.getCell(cellKey).value = cell.value; 

                } else if (metadataMapping[cell.rowKey.toLowerCase()] && monthMapping[cell.columnKey]) {
                    cellKey = metadataMapping[cell.rowKey.toLowerCase()] + monthMapping[cell.columnKey];
                    planWorksheet.getCell(cellKey).value = cell.value;
                }

            } else {
                // multi months statement
                const startMonth  = month[0];
                const endMonth = month[1];
                planWorksheet.getCell(`A${monthMapping[startMonth]}`).value = cell.columnKey;
                planWorksheet.getCell(`A${monthMapping[startMonth]}`).value = cell.columnKey;

                if (!metadataMapping[cell.rowKey]) {
                    cellKey = metadataMapping[cell.rowKey.toLowerCase()] + monthMapping[startMonth];

                } else {
                    cellKey = metadataMapping[cell.rowKey] + monthMapping[startMonth];

                }

                planWorksheet.getCell(cellKey).value = cell.value;

                for (let i = monthMapping[startMonth] + 1; i <= monthMapping[endMonth]; i++) {
                    if (!rowsToRemove[startMonth]) {
                        rowsToRemove[startMonth] = [];
                    }
                    rowsToRemove[startMonth].push(i);
                }
                rowsToRemove[startMonth] = [...new Set(rowsToRemove[startMonth])];
            }
        });

        // sort rows to remove by descending month  
        const reversedMonthOrder = newOrderNames.reverse();
        const sortedEntries = Object.entries(rowsToRemove).sort((a, b) => reversedMonthOrder.indexOf(a[0]) - reversedMonthOrder.indexOf(b[0]));

        sortedEntries.forEach(([start, removing]) => {
            planWorksheet.spliceRows(monthMapping[start] + 1, removing.length);
        });

        let formulaeIdx = 19;

        let summaryMonths = {};

        for (let i = 7; i < 20; i++) {
            if (planWorksheet.getCell(i, 1).value === 'Totals') {
                formulaeIdx = i;
                break;
            }
            summaryMonths[planWorksheet.getCell(i, 1).value] = i;
        }

        let summarySingleMonths = Object.entries(summaryMonths).reduce((acc, [month, idx]) => {
            if (month.includes('-')) {
                const lowerBound = newOrderNamesOriginal.indexOf(month.split(' - ')[0]);
                const upperBound = newOrderNamesOriginal.indexOf(month.split(' - ')[1]);
                for (let i = lowerBound; i <= upperBound; i++) {
                    acc[newOrderNamesOriginal[i]] = idx;
                }

                return {...acc};
            }
            acc[month.slice(0, 3)] = idx;
            return {...acc};
        }, {});
        
        // legend formatting
        const subtypeCount = Object.entries(this.state.subTypes).reduce((acc, [key, value]) => acc + value.length, 0);
        for (let i = formulaeIdx + 4; i <= planWorksheet.rowCount; i++) {
            for (let j = 11; j < 11 + subtypeCount + Object.keys(this.state.subTypes).length; j++ ) {
                const sourceCell = planWorksheet.getCell(i, 11 + subtypeCount +Object.keys(this.state.subTypes).length);
                const targetCell = planWorksheet.getCell(i, j);
                targetCell.fill = sourceCell.fill;
            }
        }

        let formulae = Object.values(metadataMapping).map(letter => `${letter}${formulaeIdx-1}`);
        // formulae for totals
        formulae.forEach(cell => {
            let letter = cell.split('')[0];

            if (letter === 'C') {
                return planWorksheet.getCell(`${letter}${formulaeIdx}`).value = {
                    formula: `=C7`
                }
            } else if (letter === 'D') {
                return planWorksheet.getCell(`${letter}${formulaeIdx}`).value = {
                    formula: `=D${formulaeIdx-1}`
                
                }
            }
            let index = parseInt(cell.substring(1)) + 1;
            planWorksheet.getCell(`${letter}${index}`).value = {
                formula: `=SUM(${letter}7:${cell})`
            }

            planWorksheet.getCell(`${letter}${index}`).style = JSON.parse(JSON.stringify(planWorksheet.getCell(`C${formulaeIdx}`).style));
        });

        const labelMapping = {
            'opening balance': 'beginningBalance',
            'deposits': 'deposits',
            'Withdrawals': 'withdrawals',
            'loan pmts': 'loanPayments',
            'income': 'income',
            'fees': 'fees',
            'transfers': 'transfers',
            'rollovers': 'rollovers',
            'reinvestments': 'reinvestments',
            'other': 'other',
            'gain/(loss)': 'gain_loss',
            'closing balance': 'endingBalance',
            'accruals': 'accrued',
        }
        const headersLength = Object.keys(metadataMapping).length;
        // setting formulae in the table
        Object.entries(summaryFormulae).forEach(([accountNumber, months]) => {
            // looping through the months of the summary sheet
            Object.entries(months).forEach(([month, idx]) => {
                let summaryCell;
                // if the month is in the summary months, update the formula
                if (Object.keys(summaryMonths).includes(month)) {
                    for (let i = 3; i < 3 + headersLength; i++) {
                        const cellSummary = planWorksheet.getCell(6, i).value
                        // if the column header is other type, we substrat the subtypes
                        if (cellSummary.toLowerCase().includes('other ')) {
                            const type = cellSummary.split(' ')[1];

                            const subTypeCells = this.state.subTypes[type].reduce((acc, sub) => {
                                return acc + `-${metadataMapping[sub]}${summaryMonths[month]}`;
                            }, '');

                            planWorksheet.getCell(`${metadataMapping[cellSummary.toLowerCase()]}${summaryMonths[month]}`).value = {
                                formula: `=${metadataMapping[type.toLowerCase()]}${summaryMonths[month]}${subTypeCells}`
                            };
                            continue;
                        }
                        // if the header is not in the mapping, we skip
                        if (!Object.keys(this.state.accountMetaMapping[accountNumber]).map(v => v.toLowerCase()).includes(labelMapping[cellSummary.toLowerCase()]) && 
                            !Object.keys(this.state.accountMetaMapping[accountNumber]).map(v => v.toLowerCase()).includes(cellSummary.toLowerCase()) 
                        ) {

                            continue;
                        }
                        // we reach here, we deal with default columns (e.g Deposits, Withdrawals, etc.)
                        summaryCell = planWorksheet.getCell(summaryMonths[month], i);
                        let letter = labelMapping[cellSummary.toLowerCase()] ? 
                            this.state.accountMetaMapping[accountNumber][labelMapping[cellSummary.toLowerCase()]] : 
                            this.state.accountMetaMapping[accountNumber][cellSummary.toLowerCase()];

                        if (!letter) {
                            letter = this.state.accountMetaMapping[accountNumber][cellSummary];
                            if (!letter) {

                                continue;
                            }
                        }
                        if (!summaryCell.value?.formula || summaryCell.value?.formula.includes('copy')) {
                            summaryCell.value = {
                                formula: `=SUM('Acct# ${accountNumber}'!${letter}${idx})`
                            }
                        } else {
                            summaryCell.value = {
                                formula: summaryCell.value.formula.replace(')', '') + `,'Acct# ${accountNumber}'!${letter}${idx})`
                            }
                        }
                    }
                // otherwise, we need to figure out where is the month included in the summary months
                } else {

                    if (month.includes('-')) {
                        const lowerBound = newOrderNamesOriginal.indexOf(month.split(' - ')[0]);
                        const row = summarySingleMonths[newOrderNamesOriginal[lowerBound]];

                        for (let j = 3; j < 3 + headersLength; j++) {
                            const cellSummary = planWorksheet.getCell(6, j).value
                            // if the column header is other type, we substrat the subtypes
                            if (cellSummary.toLowerCase().includes('other ')) {
                                const type = cellSummary.split(' ')[1];

                                const subTypeCells = this.state.subTypes[type].reduce((acc, sub) => {
                                    return acc + `-${metadataMapping[sub]}${row}`;
                                }, '');

                                planWorksheet.getCell(`${metadataMapping[cellSummary.toLowerCase()]}${row}`).value = {
                                    formula: `=${metadataMapping[type.toLowerCase()]}${row}${subTypeCells}`
                                };
                                continue;
                            }
                            // if the header is not in the mapping, we skip
                            if (!Object.keys(this.state.accountMetaMapping[accountNumber]).map(v => v.toLowerCase()).includes(labelMapping[cellSummary.toLowerCase()]) && 
                                !Object.keys(this.state.accountMetaMapping[accountNumber]).map(v => v.toLowerCase()).includes(cellSummary.toLowerCase()) 
                            ) {

                                continue;
                            }
                            // we reach here, we deal with default columns (e.g Deposits, Withdrawals, etc.)
                            summaryCell = planWorksheet.getCell(row, j);
                            let letter = labelMapping[cellSummary.toLowerCase()] ? 
                                this.state.accountMetaMapping[accountNumber][labelMapping[cellSummary.toLowerCase()]] : 
                                this.state.accountMetaMapping[accountNumber][cellSummary.toLowerCase()];

                            if (!letter) {
                                letter = this.state.accountMetaMapping[accountNumber][cellSummary];
                                if (!letter) {

                                    continue;
                                }
                            }
                            if (!summaryCell.value?.formula || summaryCell.value?.formula.includes('copy')) {
                                summaryCell.value = {
                                    formula: `=SUM('Acct# ${accountNumber}'!${letter}${idx})`
                                }

                            } else {
                                summaryCell.value = {
                                    formula: summaryCell.value.formula.replace(')', '') + `,'Acct# ${accountNumber}'!${letter}${idx})`
                                }
                            }
                        }

                    } else {

                        const row = summarySingleMonths[month.slice(0, 3)];
                        for (let i = 3; i < 3 + headersLength; i++) {
                            const cellSummary = planWorksheet.getCell(6, i).value;
                            // if the column header is other type, we substrat the subtypes
                            if (cellSummary.toLowerCase().includes('other ')) {
                                const type = cellSummary.split(' ')[1];

                                const subTypeCells = this.state.subTypes[type].reduce((acc, sub) => {
                                    return acc + `-${metadataMapping[sub]}${row}`;
                                }, '');

                                planWorksheet.getCell(`${metadataMapping[cellSummary.toLowerCase()]}${row}`).value = {
                                    formula: `=${metadataMapping[type.toLowerCase()]}${row}${subTypeCells}`
                                };
                                continue;
                            }
                            // if the header is not in the mapping, we skip
                            if (!Object.keys(this.state.accountMetaMapping[accountNumber]).includes(labelMapping[cellSummary.toLowerCase()]) &&
                                !Object.keys(this.state.accountMetaMapping[accountNumber]).map(v => v.toLowerCase()).includes(labelMapping[cellSummary.toLowerCase()]) && 
                                !Object.keys(this.state.accountMetaMapping[accountNumber]).map(v => v.toLowerCase()).includes(cellSummary.toLowerCase()) 
                            ) {

                                continue;
                            }
                            // we reach here, we deal with default columns (e.g Deposits, Withdrawals, etc.)
                            summaryCell = planWorksheet.getCell(row, i);
                            let letter = labelMapping[cellSummary.toLowerCase()] ? 
                                this.state.accountMetaMapping[accountNumber][labelMapping[cellSummary.toLowerCase()]] : 
                                this.state.accountMetaMapping[accountNumber][cellSummary.toLowerCase()];
                            
                            if (!letter) {
                                letter = this.state.accountMetaMapping[accountNumber][cellSummary];
                                if (!letter) {

                                    continue;
                                }
                            }
                            if (!summaryCell.value?.formula || summaryCell.value?.formula.includes('copy')) {
                                summaryCell.value = {
                                    formula: `=SUM('Acct# ${accountNumber}'!${letter}${idx})`
                                }
                            } else {
                                summaryCell.value = {
                                    formula: summaryCell.value.formula.replace(')', '') + `,'Acct# ${accountNumber}'!${letter}${idx})`
                                }
                            }
                        }
                    }
                }
            });
        });
        // set formulae for balance accrued
        for (let row = 7; row < formulaeIdx ; row++) {
            planWorksheet.getCell(`F${row}`).value = {
                formula: `=SUM(D${row}:E${row})`
            }
        }
        // planWorksheet.getCell(`F${formulaeIdx}`).value = {
        //     formula: `=F${formulaeIdx-1}`
        // }

        // download the file
        workbook.xlsx.writeBuffer().then((buffer) => {
            const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = `TPA TA Plan ${this.state.plan.internalPlanId}.xlsx`;
            link.click();
        });
        this.props.loaded(); 
    }

    renderTrustAccounting = () => {

        return (
            <div
                className='plan-summary-trust-accounting'
                style={{paddingLeft: 20}}
            >
                <div style={{display: 'flex', justifyContent: 'space-between',  marginBottom: 20}}>

                    <h3 style={{fontWeight: 600}}>Trust Accounting</h3>
                    <div
                            className='a'
                            style={{
                                marginLeft: 25, 
                                lineHeight: '33px',
                                backgroundImage: 'url("https://static.stax.ai/images/icons/action-retrain.png")',
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 16, 
                                backgroundPosition: 'left 6px', 
                                paddingLeft: 20, 
                            }}
                            onClick={async() => {
                                this.setState({loading: true});
                                await this.loadStatements(); 
                                await this.fetchTransactions();
                                this.formatToTable(); 
                                this.setState({loading: false});
                            }}  
                        >
                            Refresh data
                        </div>
                    <div 
                        className='a' 
                        style={{backgroundImage: "url('https://static.stax.ai/images/icons/action-download.png')", backgroundRepeat: 'no-repeat', marginLeft: 'auto', paddingLeft: 25, backgroundSize: 18, backgroundPositionY: 2, zIndex: 9999}}
                        onClick={async(e) => {
                            this.props.loading(9999);
                            await api.post('/tpa/ta/run', {
                                planYear: this.props.planYear,
                                plans: [this.state.plan.internalPlanId]
                            });
                            this.props.loaded();
                            toast.info('Your trust accounting sheet is being generated. It will start filing to your plan directories automatically when ready.');
                        }}
                    >
                        Save to plan directory
                    </div>
                    <div 
                        className='a investments-plan-summary-export-excel' 
                        style={{backgroundImage: "url('https://static.stax.ai/images/icons/action-export-excel.png')", backgroundRepeat: 'no-repeat', paddingLeft: 25, backgroundSize: 18, backgroundPositionY: 5, marginLeft: 15}}
                        onClick={() => this.exportPlanToExcel()}
                        >
                        Export to Excel
                    </div>
                </div>

                <Spreadsheet 
                    loading={this.state.loading}
                    columnDefs={this.state.columnDefs}
                    rowDefs={this.state.rowDefs}
                    headers={this.state.headers}
                    cells={this.state.cells}
                    query={this.props.query}
                    renderActionOpen={false}
                />
            </div>
        );
    }


    formatValue = (v) => {
        if(!v) return '-';
        const value = typeof v === 'number'? v? v>0? v.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }): `(${(v * -1).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })})`: '-': v;

        return value; 
    }     
    
    renderAccounts = () => {
        return (
            <div
                className='plan-summary-accounts'
                style={{paddingLeft: 20}}
            >
                <div style={{display:'flex', justifyContent:'space-between'}}>
                    <h2>Accounts</h2>

                    <Filter 
                        onClick={() => {
                            // this.props.modal('filter-plans', {accounts: this.state.accounts, filterAccounts: this.filterAccounts})
                            this.props.modal('filter-plan-accounts', {accounts: [...this.state.accounts]})
                        }}
                        label="Filter Accounts"
                        filterCount={Object.values(this.props.filters).filter((f) => f !== null && f?.length > 0).length} 
                        onClear={async() => {
                            await this.props.clearFilters();
                        }}
                    /> 

                </div>
                <div style={{height: 20}}/>
                <Listable 
                    columns={[
                        {
                            header: "Provider",
                            prop: "provider",
                            width: 190,
                            marginRight: 10, 
                            align: 'left',
                            cursor: 'pointer',
                            overflow: this.state.editingAccount?._id === this.state.overflowId && true,
                            textOverflow: 'ellipsis',
                            render: (item) => {
                                const Option = ({ children, ...props }) => (
                                    <components.Option {...props}>
                                        <div 
                                            style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}
                                            title={children}
                                        >
                                            {children}
                                        </div>
                                    </components.Option>
                                );

                                return (
                                    this.state.editingAccount?._id === item?._id ?
                                    <CreatableSelect 
                                        className="provider-select-container"
                                        classNamePrefix='provider-select'
                                        components={{ Option }}
                                        styles={{
                                            valueContainer: (base) => ({
                                                ...base,
                                                overflowX: 'auto',
                                            }),
                                        }}
                                        value={{
                                            value: this.state.editingAccount?.provider ?? "",
                                            label: this.state.editingAccount?.provider ?? ""
                                        }}
                                        options={this.state.investmentProviders?.map(elem => ({
                                            value: elem.DisplayName,
                                            label: elem.DisplayName
                                        }))}
                                        onChange = {(value) => this.setState({editingAccount: {...this.state.editingAccount, provider: value.value}})}
                                        isSearchable={true}
                                        placeholder="Provider..."
                                    />
                                    : 
                                    <Highlight match={this.props.query} text={item.provider} />
                                );
                            }
                        },
                        {
                            header: "Account Number",
                            prop: "number",
                            width: 200, 
                            align: 'left',
                            cursor: 'pointer',
                            render: (item) => {
                                return (
                                    this.state.editingAccount?._id === item?._id ?
                                    <input 
                                        className="investments-transactions-select-edit"
                                        type={"text"}
                                        value={this.state.editingAccount?.number}
                                        onChange={(e) => this.setState({editingAccount: {...this.state.editingAccount, number: e.target.value}}) }
                                        placeholder={"Account number..."}
                                        onKeyDown={(e) =>  e.key === "Enter" && this.saveAccount(item)}
                                    />
                                    : 
                                    <Highlight match={this.props.query} text={item.number} />
                                );
                            }
                        },
                        {
                            header: "FBO/Participant",
                            prop: "payee",
                            width: 200, 
                            align: 'left',
                            cursor: 'pointer',
                            render: (item) => {
                                return (
                                    this.state.editingAccount?._id === item?._id ?
                                    <input 
                                        className="investments-transactions-select-edit"
                                        type={"text"}
                                        value={this.state.editingAccount?.payee}
                                        onChange={(e) => this.setState({editingAccount: {...this.state.editingAccount, payee: e.target.value}}) }
                                        placeholder={"Payee name..."}
                                        onKeyDown={(e) =>  e.key === "Enter" && this.saveAccount(item)}
                                    />
                                    : 
                                    <Highlight match={this.props.query} text={item.payee} />
                                );
                            }
                        },
                        {
                            header: "Account Type",
                            prop: "accountType",
                            width: 150, 
                            align: 'left',
                            cursor: 'pointer',
                            overflow: true,
                            render: (item) => {
                                // What is the status of the plan?
                                const accountType = item.accountType; 
                                // Define the colors for each possible status... (complete, onTrack, missing, todo, na)
                                const colors = {
                                    brokerage: {
                                        backgroundColor: 'var(--color-primary)',
                                        color: 'white',
                                    },

                                    rk: {
                                        backgroundColor: 'var(--color-accent)',
                                        color: 'white',
                                    },

                                    ignore: {
                                        backgroundColor: 'var(--palette-color-7)',
                                        color: 'white',
                                    },
                                }
                                return (
                                    this.state.editingAccount?._id === item?._id ?
                                    <Select 
                                        className="provider-select-container"
                                        classNamePrefix='provider-select'
                                        value={{label: this.state.editingAccount?.accountType??"Unknown", value: this.state.editingAccount?.accountType}}
                                        options={["brokerage", "rk", "ignore"].map(v => ({value: v, label: v.toUpperCase()}))}
                                        onChange = {(e) => {this.setState({ editingAccount: {...this.state.editingAccount, accountType: e.value}})}}
                                    />
                                    : 
                                    <div
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'start',
                                        }}
                                    ><ColoredTag {...colors[accountType]} label={accountType}/></div>
                                );
                            }
                        },
                        {
                            header: "Status",
                            prop: "status", 
                            sortComparitor: (item => item.status?.[this.props.planYear] || 'missing'),
                            width: 100, 
                            align: 'left',
                            cursor: 'pointer',
                            render: (item) => {
                                const status = item.status?.[this.props.planYear] || 'missing'; 
                                return <ColoredTag {...this.TAGCOLORS[status]} color='white' label={status}/> ;
                            }
                        },
                        {
                            header: "Account Balance",
                            prop: "assets",
                            width: 150, 
                            align: 'right',
                            cursor: 'pointer',
                            render: (item) => {
                                return this.formatValue(item.assets??0);
                            }
                        },
                        {
                            header: "",
                            prop: "edit",
                            width: 150, 
                            align: 'left',
                            cursor: 'pointer',
                            render: (item) => {
                                return (
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: 20,
                                        }}
                                    >   
                                        {
                                            this.state.editingAccount?._id === item?._id ?
                                            this.state.isSaving ?
                                            <p>Saving...</p>
                                            :
                                            <>
                                                <div
                                                    className='a investments-transactions-edit'
                                                    style={{
                                                        paddingLeft: 20,
                                                        backgroundImage: 'url("https://static.stax.ai/images/icons/confirm.png")',
                                                    }}
                                                    onClick={async () => await this.saveAccount(item)}
                                                >
                                                    Save
                                                </div>
                                                <div
                                                    className='a investments-transactions-edit'
                                                    style={{
                                                        backgroundImage: 'url("https://static.stax.ai/images/icons/delete.png")',
                                                        color: 'var(--color-accent-dark)'
                                                    }}
                                                    onClick={() => {
                                                        this.setState({ editingAccount: null,
                                                            filteredAccounts: this.state.filteredAccounts.filter(t => t._id !== "new"),
                                                            overflowId: null,
                                                        });
                                                    }}
                                                >
                                                    Cancel
                                                </div>
                                            </>                                         
                                            :
                                            <>
                                                <div
                                                    className='a investments-transactions-edit'
                                                    style={{
                                                        backgroundImage: 'url("https://static.stax.ai/images/icons/edit.png")',
                                                    }}
                                                    onClick={() =>{
                                                        this.setState({editingAccount: {...item},
                                                            filteredAccounts: this.state.filteredAccounts.filter(t => t._id !== "new"),
                                                            overflowId: item._id,
                                                        });
                                                    }}
                                                >
                                                    Edit
                                                </div>
                                                <div
                                                    className='a investments-transactions-edit'
                                                    style={{
                                                        backgroundImage: 'url("https://static.stax.ai/images/icons/delete.png")',
                                                        color: 'var(--color-accent-dark)'
                                                    }}
                                                    onClick={async () => {
                                                        const res = await api.post('/tpa/account/remove', {
                                                            id: item._id
                                                        });
                                                        if(!res.success)
                                                            return toast.error("Failed to delete account");

                                                        this.setState({
                                                            accounts: this.state.accounts.filter(t => t._id !== item._id), 
                                                            filteredAccounts: this.state.filteredAccounts.filter(t => t._id !== item._id)
                                                        });

                                                        // Refilter the accounts...
                                                        this.filterAccounts();
                                                    }}
                                                >
                                                    Delete
                                                </div>
                                            </>
                                        }
                                        
                                    </div>
                                )
                            }
                        },
                    ]}
                    data={[...this.state.filteredAccounts]}
                    debug={false}
                    onRowClick={(item) => {
                        if (!this.state.editingAccount) window.open(`/investments/plan/${item.planId}/account/${item._id}?planYear=${this.props.planYear}`, '_blank') ;
                    }}
                    stickyHeader={true}
                /> 
            </div>
        );
    
    }


    render = () => {
        return(
            <div>
                {this.renderPlanInformation()}
                {this.renderTrustAccounting()}
                <div style={{clear: 'both', height: 40}}/>
                {this.renderAccounts()}
            </div>
        );
    }
}